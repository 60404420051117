<template>
    <div class="text-center">
        <template v-if="checkPermission('UserGroupResource.PUT.UserGroup')">
        <button
            @click="editPermission()"
            class="btn btn-sm btn-clean btn-icon btn-icon-md"
            title="Edit"
        >
            <i class="la la-pencil-square"></i>
        </button>
        </template>
        <template v-if="checkPermission('UserGroupPermissionResource.DELETE.UserGroupPermission.id')">
        <button
            @click="deletePermission()"
            class="btn btn-sm btn-clean btn-icon btn-icon-md"
            title="Hapus"
        >
            <i class="la la-trash"></i>
        </button>
        </template>
    </div>
</template>

<script>
import CheckPermission from "../../../services/checkPermission.service";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
const UserGroupRepository = RepositoryFactory.get("userGroup");
const checkPermission = new CheckPermission();
const SweetAlert = Vue.extend(SweetAlertTemplate);
export default {
    props: {
        formModalVue: { type: Object },
        rowData: { type: Object },
        rowIndex: null
    },
    methods: {
        deletePermission() {
            var vx = this;
            let instance = new SweetAlert().showConfirm({
                swalTitle: "Apakah Anda yakin?",
                swalText: "Data Permission akan terhapus.",
                swalType: "warning",
                onConfirmButton: function () {
                    blockUI.blockPage();
                    vx.delete();
                },
                onCancelButton: function () {
                    blockUI.unblockPage();
                }
            });
        },
        async delete() {
            var vx = this;

            new Promise((resolve, reject) => {
                vx.formModalVue.deletePermission(vx.rowIndex, resolve);
            }).then((response) => {
                let instance = new SweetAlert().showInfo({
                swalTitle: "Berhasil!",
                swalText: "Data Permission berhasil terhapus.",
                swalType: "success",
                onConfirmButton: function () {
                    blockUI.unblockPage();
                }
                });
            });

        },
        editPermission() {
            this.formModalVue.userGroupPermission =  Object.assign({}, this.rowData);
            this.formModalVue.rowIndexPermission = this.rowIndex;
            this.formModalVue.buttonMode = false;
        },
        checkPermission: function (method) {
            return checkPermission.isCanAccess(method);
        }
    }
}
</script>
