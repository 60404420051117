<template>
    <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">User Group</h5>
            </div>
            <div class="modal-body">
                <input
                    type="hidden"
                    class="form-control"
                    v-model.number="userGroup.id"
                />
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Nama:</label>
                    <div class="col-lg-6">
                        <input
                            type="text"
                            class="form-control"
                            v-model="userGroup.name"
                            v-validate="'required'"
                            data-vv-as="Nama"
                            data-vv-scope="form-1"
                            name="nama"
                            :class="{'is-invalid': errors.has('form-1.nama') }"/>
                        <div v-show="errors.has('form-1.nama')" class="invalid-feedback">{{ errors.first('form-1.nama') }}</div>
                    </div>
                </div>
                <div class="kt-separator kt-separator--border-dashed"></div>
                <div class="kt-separator kt-separator--height-sm"></div>
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Permission:</label>
                    <div class="col-lg-6">
                        <div :class='{"kt-spinner fix-spinner-index kt-spinner--sm kt-spinner--brand kt-spinner--right kt-spinner--input" : showLoading }'>
                            <div class="typeahead">
                                <input
                                    class="form-control"
                                    id="kt_typeahead_1"
                                    type="text"
                                    dir="ltr"
                                    v-validate="'required|lsPermission'"
                                    data-vv-as="permission"
                                    data-vv-scope="form-2"
                                    data-vv-validate-on="none"
                                    v-model="userGroupPermission.permission"
                                    name="permission"
                                    :class="{'is-invalid': errors.has('form-2.permission') }"
                                />
                            </div>
                            <small v-show="errors.has('form-2.permission')" class="text text-danger" >{{ errors.first('form-2.permission') }}</small>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Service Name:</label>
                    <div class="col-lg-6">
                        <input
                            type="text"
                            class="form-control"
                            v-model="userGroupPermission.serviceName"
                            v-validate="'required'"
                            data-vv-as="Service Name"
                            data-vv-validate-on="none"
                            data-vv-scope="form-2"
                            name="serviceName"
                            :class="{'is-invalid': errors.has('form-2.serviceName') }"/>
                        <div v-show="errors.has('form-2.serviceName')" class="invalid-feedback">{{ errors.first('form-2.serviceName') }}</div>
                    </div>
                    <div class="col-lg-3">
                        <div v-if="buttonMode">
                            <button class="btn btn-default btn-bold btn-upper btn-font-md" type="button" @click="addPermission()">Add</button>
                        </div>
                        <div v-else>
                            <button class="btn btn-default btn-bold btn-upper btn-font-md" type="button" @click="editPermission()">Update</button>
                            <button class="btn btn-clean btn-bold btn-upper btn-font-md" type="button" @click="cancelPermission()">Cancel</button>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body p-0 table-responsive">
                    <data-tables :table="table"></data-tables>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-clean btn-bold btn-upper btn-font-md" @click="confirmClose()" >Close</button>
                <button type="button" class="btn btn-default btn-bold btn-upper btn-font-md" @click="submitData()">Save</button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);
import UserGroup from "../../../model/user-group-model";
import UserGroupPermission from "../../../model/user-group-permission-model";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const UserGroupRepository = RepositoryFactory.get("userGroup");
import DataTables from "../../_table/DataTables";
import BtnActionPermission from "./BtnActionPermission";
import { Promise } from 'q';
import { Validator } from 'vee-validate';
export default {
  components: {
    DataTables,
    BtnActionPermission
  },
  data() {
    var vx = this;
    return {
        userGroup : new UserGroup(),
        userGroupPermission: new UserGroupPermission(),
        rowIndexPermission: null,
        showLoading: false,
        buttonMode: true,
        flagEdit: false,
        table: {
            tableName: "table_permissions",
            tableDataResult: [],
            tableColumnDefs: [
                {
                    defaultContent: "-",
                    targets: "_all"
                },
                {
                    targets: 0
                }
            ],
            order: [[ 0, "asc" ]],
            tableColumns: [
                {
                    title: "ID",
                    data: "id"
                },
                {
                    title: "Permission",
                    data: "permission"
                },
                {
                    title: "Service Name",
                    data: "serviceName"
                },
                {
                    title: "Actions",
                    data: "id",
                    createdCell: function(cell, cellData, rowData, rowIndex, colIndex) {
                        let btnActionPermission = Vue.extend(BtnActionPermission);
                        let instance = new btnActionPermission({
                            propsData: {
                                formModalVue: vx,
                                rowData: rowData,
                                rowIndex: rowIndex
                            }
                    });
                    instance.$mount();
                    $(cell)
                        .empty()
                        .append(instance.$el);
                    }
                }
            ],
            tableFilter: [
                {
                    title: "Permission",
                    type: "text"
                },
                {
                    title: "Service Name",
                    type: "text"
                },
                {
                    title: "Actions",
                    type: "actions"
                }
            ]
        }
    };
  },
  props: {
    modalName: { type: String },
    modalData: { type: Object }
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  watch: {
    "userGroupPermission.permission": function () {
        if (this.userGroupPermission.permission && this.userGroupPermission.permission.length < 1) {
            this.showLoading = false;
        }
    }

  },
  methods: {
    addPermission: function () {
        this.$validator.validateAll('form-2').then(result => {
            if (result) {
                this.userGroup.userGroupPermissions.unshift(this.userGroupPermission);
                this.flagEdit = true;
                this.userGroupPermission = new UserGroupPermission();
                this.reloadTable("full-reset");
                this.showToastSuccess("Success menambahkan permission ke listpermission");
            }
        });
    },
    cancelPermission: function(){
        this.userGroupPermission = new UserGroupPermission();
        this.buttonMode = true;
    },
    editPermission: function(){
        this.$validator.validateAll('form-2').then(result => {
            if (result) {
                this.userGroup.userGroupPermissions[this.rowIndexPermission] = this.userGroupPermission;
                this.userGroupPermission = new UserGroupPermission();
                this.buttonMode = true;
                this.flagEdit = true;
                this.reloadTable("full-hold");
                this.showToastSuccess("Success update permission di listpermission");
             }
        });
    },
    async deletePermission(index , resolve){
        this.userGroup.userGroupPermissions.splice(index,1);
        this.buttonMode = true;
        this.flagEdit = true;
        this.reloadTable("full-hold");
        resolve(this.userGroup);
    },
    confirmClose: function(){
        if ( this.flagEdit ) {
            var vx = this;
            blockUI.blockPage();
            let instance = new SweetAlert().showConfirm({
                swalTitle: "Apakah Anda yakin keluar?",
                swalText: "Perubahan list permission akan dibatalkan.",
                swalType: "info",
                onConfirmButton: function () {
                    blockUI.unblockPage();
                    vx.clearData();
                },
                onCancelButton: function () {
                    blockUI.unblockPage();
                }
            });
        } else {
            this.clearData();
        }
    },
    clearData: function () {
        $("#" + this.modalName).modal("hide");
        this.$emit("update:showModal", false);
    },
    reloadTable: function(mode) {
        var datatable = $("#" + this.table.tableName).DataTable();
        datatable.clear();
        datatable.rows.add(this.userGroup.userGroupPermissions);
        datatable.draw(mode);
    },
    updateData: function (data) {
        this.userGroup = Object.assign({},data);
        this.reloadTable("full-hold");
    },
    submitData: function () {
      var vx = this;

      this.$validator.validateAll('form-1').then(result => {
        if (result) {
          if (vx.userGroup.id) {
            let instance = new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Perubahan data User Group akan tersimpan.",
              swalType: "info",
              onConfirmButton: function () {
                blockUI.blockModal();
                vx.update(vx.userGroup);
              },
              onCancelButton: function () {
                blockUI.unblockModal();
              }
            });
          } else {
            let instance = new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Data User Group akan ditambahkan.",
              swalType: "info",
              onConfirmButton: function () {
                blockUI.blockModal();
                vx.create(vx.userGroup);
              },
              onCancelButton: function () {
                blockUI.unblockModal();
              }
            });
          }
          return;
        }
      });
    },
    async update(payload) {
      var vx = this;

      await UserGroupRepository.updateUserGroup(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Perubahan data User Group berhasil disimpan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Perubahan data User Group gagal disimpan.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    },
    async create(payload) {
      var vx = this;
      await UserGroupRepository.createUserGroup(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Data User Group berhasil ditambahkan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Data User Group gagal ditambahkan.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    },
    async renderPermission() {
        new Promise((resolve, reject) => {
            UserGroupRepository.getPermission({}, resolve, reject );
        }).then(response => {
            const data = response.data.map(x => x.permission);
            const distinctData = [... new Set(data)];
            this.typeahead(distinctData);
            this.showLoading = false;
        }).catch(error => {
            var msg = (error.message) ? error.message : error;
            this.showToastError('User Groups : ' + msg);
        })

    },
    typeahead: function () {
        var vx = this;

        var splitResult = [];

        var listPermission = new Bloodhound({
            remote: {
            url: process.env.VUE_APP_API_URL + "/api/_search/permissions",
            prepare: function (query, settings) {
                vx.showLoading = true;
                settings.url = settings.url + "?query=name%3A*" + query + "*";
                settings.headers = {
                Authorization: "Bearer " + access_token,
                "cache-control": "no-cache"
                };

                return settings;
            }
            },
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace("name")
        });

        var myTypeAhead = $("#kt_typeahead_1")
            .typeahead(
            {
                hint: true,
                highlight: true,
                minLength: 4
            },
            {
                name: "listPermission",
                limit: 10,
                display: function (item) {
                    return (
                        item.name
                    );
                },
                source: listPermission,
                templates: {
                    suggestion: Handlebars.compile(
                        "<div><strong>{{name}}</strong></div>"
                    )
                }
            }
            )
            .bind("typeahead:render", function () {
                vx.showLoading = false;
            })
            .bind("typeahead:select", function ($e, data) {
                vx.userGroupPermission.permission = data.name;
                vx.userGroupPermission.serviceName = data.serviceName;
                $('#'+vx.modalName).click();
            });
    },
    showToastError: function(message) {
        toastr.error(message);
    },
    showToastSuccess: function(message) {
        toastr.success(message);
    }
  },
  mounted() {
    $("#" + this.modalName).modal();
    if (this.modalData) {
        this.updateData(this.modalData);
    }
    this.typeahead();
    Validator.extend('lsPermission', {
        getMessage: field => 'Data Permission sudah terdapat didalam list permission',
        validate: value => {
            if(this.buttonMode) {
                return ( this.userGroup.userGroupPermissions.find(x => x.permission == value) ) ? false : true;
            } else {
                return ( this.userGroup.userGroupPermissions.filter(x => x.permission == value).length > 1 ) ? false : true;
            }
        }
    });
  }
}
</script>
